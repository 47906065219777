import { configureStore, combineSlices } from "@reduxjs/toolkit";

import { businessModalSlice } from "./slices/businessModalSlice";
import { monthlyEventsSlice } from "./slices/monthlyEventsSlice";
import { articleSlice } from "./slices/articleSlice";
import { inTheNewsSlice } from "./slices/inTheNewsSlice";
import { categoriesSlice } from "./slices/categoriesSlice";
import { endpointsSlice } from "./slices/endpoints.slice";

const rootReducer = combineSlices(
    articleSlice,
    businessModalSlice,
    endpointsSlice,
    monthlyEventsSlice,
    inTheNewsSlice,
    categoriesSlice
);

export const store = configureStore({
    reducer: rootReducer,
});
