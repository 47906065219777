import { createAppSlice } from "./createAppSlice";

const initialState = {
    contact: "",
    demo: "",
    lead: "",
    facilityReferral: "",
    savingsCalculator: "",
    friendReferral: "",
    referral: "",
    facilitySupport: "",
    businessInfo: "",
    professionalInfo: "",
    csuReferral: "",
    postAcuteLead: "",
    mspSubVendor: "",
};

export const endpointsSlice = createAppSlice({
    name: "endpoints",
    initialState,
    reducers: {
        setEndpoints: (state, action) => {
            state = action.payload;
            return state;
        },
    },
    selectors: {
        selectMspSubVendorEndpoint: state => state.mspSubVendor,
    },
});

export const { setEndpoints } = endpointsSlice.actions;

export const { selectMspSubVendorEndpoint } = endpointsSlice.selectors;
